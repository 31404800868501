import './App.css';
import WebSocketAsPromised from 'websocket-as-promised';
import {haloFindBridge} from "@arx-research/libhalo/api/web";
import {useEffect, useState} from "react";
import axios from "axios";
import {API_ADDR} from "./Config";
import {db} from "./db";
import {Dexie} from "dexie";
import {ethers} from "ethers";

let successAudio = new Audio("success.wav");
let errorAudio = new Audio("error.wav");


function App() {
    const [credentials, setCredentials] = useState({
        confirmed: false,
        login: "",
        password: "",
        subdomain: ""
    });
    const [bridgeFound, setBridgeFound] = useState(false);
    const [bridgeState, setBridgeState] = useState({
        text: "Connecting...",
        status: "normal"
    });
    const [numEntries, setNumEntries] = useState(0);

    function onChange(inputName, ev) {
      setCredentials({...credentials, [inputName]: ev.target.value});
    }

    function onKeyDown(ev) {
      if (ev.keyCode === 13) {
        btnAuthenticate();
      }
    }

    async function btnAuthenticate() {
      try {
        await axios.post(API_ADDR + '/subdomain_auth', {
          "login": credentials.login,
          "password": credentials.password,
          "subdomain": credentials.subdomain
        });
      } catch (e) {
        alert("Invalid credentials.");
        return;
      }

      setCredentials({...credentials, confirmed: true});
    }

    async function btnDownloadJSON() {
        let out = await db.tags.toArray();

        let blob = new Blob([JSON.stringify(out, null, 4)], {type: "application/json"});
        let link = document.createElement("a");
        link.href = window.URL.createObjectURL(blob);
        link.download = "halo-subdomain-tool-export.json";
        link.click();
    }

    async function btnClearDB() {
        if (window.confirm("Do you really want to clear the database?")) {
            await db.tags.clear();
            setNumEntries(0);
        }
    }

    async function processHandle(ws, handle, creds) {
        console.log('processHandle called');

        let pkeysRes = await ws.sendRequest({
            "type": "exec_halo",
            "handle": handle,
            "command": {
                "name": "get_pkeys"
            }
        });

        if (pkeysRes.event !== 'exec_success') {
            errorAudio.play();
            setBridgeState({
                text: 'Scan failed: Failed to get tag\'s public keys. Please try again.',
                status: 'error'
            });
            return;
        }

        let getChallRes;

        try {
            getChallRes = await axios.post(API_ADDR + '/subdomain_get_challenge', {
                "login": creds.login,
                "password": creds.password,
                "subdomain": creds.subdomain,
                "pk1": pkeysRes.data.res.publicKeys[1]
            });
        } catch (e) {
            errorAudio.play();
            setBridgeState({
                text: 'Failed to connect with the Arx server to obtain token.',
                status: 'error'
            });
            return;
        }

        let presenceProofRes = await ws.sendRequest({
            "type": "exec_halo",
            "handle": handle,
            "command": {
                "name": "sign",
                "digest": getChallRes.data.challenge,
                "keyNo": 1
            }
        });

        if (presenceProofRes.event !== 'exec_success') {
            errorAudio.play();
            setBridgeState({
                text: 'Scan failed: Failed to create presence proof. Please try again.',
                status: 'error'
            });
            return;
        }

        let customSigRes = null;

        if (getChallRes.data.extra && getChallRes.data.extra.sig) {
            let command;

            if (getChallRes.data.extra.sig.alg === 'pack_addr_eip191') {
                let message = ethers.utils.solidityPack(["address"], [getChallRes.data.extra.sig.data]);

                if (message.startsWith('0x')) {
                    message = message.slice(2);
                }

                command = {
                    "name": "sign",
                    "message": message,
                    "keyNo": 1,
                    "format": "hex"
                };
            } else {
                errorAudio.play();
                setBridgeState({
                    text: 'Configuration error: Unknown sig.alg value.',
                    status: 'error'
                });
                return;
            }

            customSigRes = await ws.sendRequest({
                "type": "exec_halo",
                "handle": handle,
                "command": command
            });

            if (customSigRes.event !== 'exec_success') {
                errorAudio.play();
                setBridgeState({
                    text: 'Scan failed: Failed to create custom signature. Please try again.',
                    status: 'error'
                });
                return;
            }
        }

        if (getChallRes.data.extra && getChallRes.data.extra.cfg_ndef) {
            let cfgNdefRes = await ws.sendRequest({
                "type": "exec_halo",
                "handle": handle,
                "command": {
                    "name": "cfg_ndef",
                    ...getChallRes.data.extra.cfg_ndef
                }
            });

            if (cfgNdefRes.event !== 'exec_success') {
                errorAudio.play();
                setBridgeState({
                    text: 'Scan failed: Failed to perform cfg_ndef. Please try again.',
                    status: 'error'
                });
                return;
            }
        }

        let allowSigRes;

        try {
            allowSigRes = await axios.post(API_ADDR + '/subdomain_sig', {
                "token": getChallRes.data.token,
                "presenceProof": presenceProofRes.data.res.signature.der,
                "customSignature": customSigRes ? customSigRes.data.res.signature.ether : null
            });
        } catch (e) {
            errorAudio.play();
            setBridgeState({
                text: 'Failed to connect with the Arx server to obtain signature.',
                status: 'error'
            });
            return;
        }

        let res = await ws.sendRequest({
            "type": "exec_halo",
            "handle": handle,
            "command": {
                "name": "set_url_subdomain",
                "subdomain": creds.subdomain,
                "allowSignatureDER": allowSigRes.data.sig
            }
        });

        if (res.event === 'exec_success') {
            let ndefRes = await ws.sendRequest({
                "type": "exec_halo",
                "handle": handle,
                "command": {
                    "name": "read_ndef"
                }
            });

            if (ndefRes.event === 'exec_success') {
                try {
                    await db.tags.add({
                        pk1: pkeysRes.data.res.publicKeys[1],
                        pk2: pkeysRes.data.res.publicKeys[2],
                        pk3: pkeysRes.data.res.publicKeys[3],
                        sig: customSigRes ? customSigRes.data.res.signature.ether : null,
                        ts: +new Date()
                    });
                } catch (e) {
                    if (e instanceof Dexie.ConstraintError) {
                        // ignore
                    }
                }
                successAudio.play();
                setBridgeState({
                    text: 'Scanned OK.',
                    status: 'success'
                });
                setNumEntries(await db.tags.count());
            } else {
                errorAudio.play();
                setBridgeState({
                    text: 'Scan failed: Unable to read NDEF contents after setting the subdomain.',
                    status: 'error'
                });
            }
        } else if (res.event === 'exec_exception') {
            if (res.data.exception.message.includes('[ERROR_CODE_SUBDOMAIN_LOCKED]')) {
                let ndefRes = await ws.sendRequest({
                    "type": "exec_halo",
                    "handle": handle,
                    "command": {
                        "name": "read_ndef"
                    }
                });

                if (ndefRes.data.res.url === 'https://' + creds.subdomain + '.vrfy.ch/') {
                    try {
                        await db.tags.add({
                            pk1: pkeysRes.data.res.publicKeys[1],
                            pk2: pkeysRes.data.res.publicKeys[2],
                            pk3: pkeysRes.data.res.publicKeys[3],
                            sig: customSigRes ? customSigRes.data.res.signature.ether : null,
                            ts: +new Date()
                        });
                    } catch (e) {
                        if (e instanceof Dexie.ConstraintError) {
                            // ignore
                        }
                    }
                    successAudio.play();
                    setBridgeState({
                        text: 'Scanned OK.',
                        status: 'success',
                    });
                    setNumEntries(await db.tags.count());
                } else {
                    errorAudio.play();
                    setBridgeState({
                        text: 'Scan failed: This tag is already configured with a different subdomain. It\'s not possible to change the subdomain.',
                        status: 'error'
                    });
                }
            } else {
                errorAudio.play();
                setBridgeState({
                    text: 'Scan failed: ' + res.data.exception.message,
                    status: 'error'
                });
            }
        } else {
            errorAudio.play();
            setBridgeState({
                text: 'Scan failed. Unknown state.',
                status: 'error'
            });
        }
    }

    useEffect(() => {
        async function locateBridge() {
            let numEntries = await db.tags.count();

            setNumEntries(numEntries);

            let bridgeAddr;

            try {
                bridgeAddr = await haloFindBridge();
            } catch (e) {
                setBridgeState({
                    text: 'Unable to connect with HaLo Bridge, please ensure it\'s running on your machine.',
                    status: 'error'
                });
                return;
            }

            const wsp = new WebSocketAsPromised(bridgeAddr, {
                packMessage: data => JSON.stringify(data),
                unpackMessage: data => JSON.parse(data)
            });

            wsp.onUnpackedMessage.addListener(data => {
                if (data.event === 'ws_connected') {
                    setBridgeFound(true);
                } else {
                    setBridgeState({
                        text: 'Unable to connect with HaLo Bridge, unexpected packet received on open.',
                        status: 'error'
                    });
                }

                wsp.close();
            });

            wsp.onClose.addListener(event => {
                if (event.code === 4002) {
                    // we need to obtain user's consent in order to use HaLo Bridge
                    window.location.href = 'http://127.0.0.1:32868/consent?website=https://halo-subdomain-tool.arx.org';
                } else {
                    console.log('Connection closed due to: [' + event.code + '] ' + event.reason);
                }
            });

            await wsp.open();
        }

        locateBridge();
    }, []);

    useEffect(() => {
        async function startScanning() {
            let bridgeAddr;

            try {
                bridgeAddr = await haloFindBridge();
            } catch (e) {
                setBridgeState({
                    text: 'Unable to connect with HaLo Bridge, please ensure it\'s running on your machine.',
                    status: 'error'
                });
                return;
            }

            console.log('making ws connection...');
            const wsp = new WebSocketAsPromised(bridgeAddr, {
                packMessage: data => JSON.stringify(data),
                unpackMessage: data => JSON.parse(data),
                attachRequestId: (data, requestId) => Object.assign({uid: requestId}, data),
                extractRequestId: data => data && data.uid
            });

            wsp.onUnpackedMessage.addListener(data => {
                if (data.event === 'handle_added') {
                    processHandle(wsp, data.data.handle, credentials);
                } else if (data.event === 'handle_removed') {
                    setBridgeState({
                        text: 'Tap the card to the reader.',
                        status: 'normal'
                    });
                } else {
                    // console.log('other packet', data);
                }
            });

            wsp.onClose.addListener(event => {
                console.log('Connection closed due to: [' + event.code + '] ' + event.reason);
            });

            wsp.onError.addListener(event => {
                console.log('WS Error', event);
            });

            await wsp.open();
            setBridgeFound(true);
            setBridgeState({
                text: 'Tap the card to the reader.',
                status: 'normal'
            });

            console.log('ws opened');
        }

        if (credentials.confirmed) {
            startScanning();
        }
    }, [credentials]);

    let color = 'white';

    if (bridgeState.status === 'error') {
        color = 'red';
    } else if (bridgeState.status === 'success') {
        color = 'green';
    }

    if (bridgeFound && !credentials.confirmed) {
        return (
            <div className="App">
                <header className="App-header">
                    <p>
                        Please enter your credentials:
                    </p>
                    Login:<br/>
                    <input type={"text"} value={credentials.login}
                           onChange={(ev) => onChange('login', ev)}
                           onKeyDown={(ev) => onKeyDown(ev)} /><br/>
                    Password:<br/>
                    <input type={"password"} value={credentials.password}
                           onChange={(ev) => onChange('password', ev)}
                           onKeyDown={(ev) => onKeyDown(ev)} /><br/>
                    Subdomain name:<br/>
                    <input type={"text"} value={credentials.subdomain}
                           onChange={(ev) => onChange('subdomain', ev)}
                           onKeyDown={(ev) => onKeyDown(ev)} /><br/>
                    <button type={"button"} onClick={() => btnAuthenticate()}>Authenticate</button>
                </header>
            </div>
        );
    }

    return (
        <div className="App">
            <header className="App-header">
                <p style={{color: color}}>
                    {bridgeState.text}
                </p>
                <button type={"button"} onClick={() => btnDownloadJSON()}>Download JSON ({numEntries})</button>
                <button type={"button"} onClick={() => btnClearDB()}>Clear database</button>
            </header>
        </div>
    );
}

export default App;
